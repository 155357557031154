import React, { lazy, Suspense } from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
// react library for routing
import { BrowserRouter, Route, Switch } from "react-router-dom";

// plugins styles from node_modules
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "./assets/vendor/nucleo/css/nucleo.css";
// core styles
import "./assets/css/global.css";
import AuthRoute from "./components/PrivateRoute/AuthRoute";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import config from "./config";
// import DashboardRoute from "components/PrivateRoute/DashboardRoute";
import { UserDataProvider } from "./UserDataContext";
import LoadingPage from "./views/LoadingPage";

const ExternalRedirect = lazy(
  () => import("./views/pages/components/ExternalRedirect"),
);
const Vendors = lazy(() => import("./views/Vendors"));
const PartCreate = lazy(() => import("./views/PartCreate"));
const Parts = lazy(() => import("./views/Parts"));
const Documents = lazy(() => import("./views/Documents"));
const DocumentsDetails = lazy(() => import("./views/DocumentsDetails"));
const ChatSetPage = lazy(() => import("./views/ChatSetPage"));
const ContractPage = lazy(() => import("./views/ContractPage"));
const SearchChatbotPage = lazy(() => import("./views/SearchChatbotPage"));
const SearchChatbotPageD = lazy(() => import("./views/SearchChatbotPageDemo"));
const SearchChatbotPageDS = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan"),
);
const SearchChatbotPageDS2 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan2"),
);
const SearchChatbotPageDS3 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan3"),
);
const SearchChatbotPageDS4 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan4"),
);
const SearchChatbotPageDS5 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan5"),
);
const SearchChatbotPageDS6 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan6"),
);
const AnalyticsChatbotPageDS6 = lazy(
  () => import("./views/AnalyticsChatbotPageDemo-srijan6"),
);
const SearchChatbotPageDS7 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan7"),
);

const SearchChatbotPageDS8 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan9"),
);
const SearchChatbotPageDS11 = lazy(
  () => import("./views/SearchChatbotPageDemo-srijan11"),
);

// const InvoiceChatbotPageDS8 = lazy(
//   () => import("./views/SearchChatbotPageDemo-srijan8"),
// );

const AllProductsPage = lazy(() => import("./views/Parts-subframe.tsx"));
const AllSetsPage = lazy(() => import("./views/Sets-subframe.tsx"));
const OneProductPage = lazy(() => import("./views/OnePart-subframe.tsx"));
const SetVendorsPage = lazy(() => import("./views/SetVendorsPage.tsx"));

const DashboardPage = lazy(() => import("./views/Dashboard"));
const AnalyticsPage = lazy(() => import("./views/Analytics"));

const RfqVsQuoteChecker = lazy(
  () => import("./views/Widgets/RfqVsQuoteChecker"),
);
const InvoiceVsPOChecker = lazy(
  () => import("./views/Widgets/InvoiceVsPOChecker"),
);
const InvoiceVsPOCheckerDemo = lazy(
  () => import("./views/Widgets/InvoiceVsPOCheckerDemo"),
);
const AcknowledgementVsPOChecker = lazy(
  () => import("./views/Widgets/AcknowledgementVsPOChecker"),
);
const PackingSlipVsPOChecker = lazy(
  () => import("./views/Widgets/PackingSlipVsPOChecker"),
);
const PackingSlipVsPOCheckerDemo = lazy(
  () => import("./views/Widgets/PackingSlipVsPOCheckerDemo"),
);
const A = lazy(() => import("./views/Widgets/a"));
const POUpload = lazy(() => import("./views/Widgets/POUploadPage"));

const AuthLayout = lazy(() => import("./layouts/Auth.js"));
const TermsView = lazy(() => import("./views/Terms.js"));
const PrivacyView = lazy(() => import("./views/Privacy.js"));
const SuccessPage = lazy(() => import("./views/Success.tsx"));

const rootElement = document.getElementById("root");

createRoot(rootElement).render(
  <GoogleOAuthProvider clientId={config.GoogleSSO_CLIENT_ID}>
    <UserDataProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <AuthRoute path="/auth" component={AuthLayout} />
            <Route path="/terms" component={TermsView} />
            <Route path="/privacy" component={PrivacyView} />
            <Route path={"/success"} component={SuccessPage} />

            <PrivateRoute path={"/parts/new"} component={PartCreate} />
            <PrivateRoute
              path={"/parts-old/:part_id/vendors"}
              component={Vendors}
            />
            <PrivateRoute path={"/parts-old"} component={Parts} />
            <PrivateRoute
              path={"/parts/:part_id/vendors"}
              component={OneProductPage}
            />
            <PrivateRoute path={"/parts"} component={AllProductsPage} />

            <PrivateRoute
              path={"/orders/:set_id/vendors"}
              component={SetVendorsPage}
            />
            <PrivateRoute path={"/orders"} component={AllSetsPage} />
            <PrivateRoute path={"/order-chat/:setId"} component={ChatSetPage} />
            <PrivateRoute
              path={"/documents/:alertId"}
              component={DocumentsDetails}
            />
            <PrivateRoute path={"/documents"} component={Documents} />
            <PrivateRoute path={"/chatbot"} component={SearchChatbotPage} />
            <PrivateRoute path={"/chatbotd"} component={SearchChatbotPageD} />
            <PrivateRoute path={"/chatbotds"} component={SearchChatbotPageDS} />
            <PrivateRoute
              path={"/chatbotds2"}
              component={SearchChatbotPageDS2}
            />
            <PrivateRoute
              path={"/chatbotds3"}
              component={SearchChatbotPageDS3}
            />
            <PrivateRoute
              path={"/chatbotds4"}
              component={SearchChatbotPageDS4}
            />
            <PrivateRoute
              path={"/chatbotds5"}
              component={SearchChatbotPageDS5}
            />
            <PrivateRoute
              path={"/chatbotds6"}
              component={SearchChatbotPageDS6}
            />
            <PrivateRoute
              path={"/analyticschatbot"}
              component={AnalyticsChatbotPageDS6}
            />
            <PrivateRoute
              path={"/chatbotds7"}
              component={SearchChatbotPageDS7}
            />

            <PrivateRoute
              path={"/chatbotds8"}
              component={SearchChatbotPageDS8}
            />
            <PrivateRoute
              path={"/chatbotds11"}
              component={SearchChatbotPageDS11}
            />

            <Route path={"/quotecheck"} component={RfqVsQuoteChecker} />

            <PrivateRoute
              path={"/invoiceverify"}
              component={InvoiceVsPOChecker}
            />
            <PrivateRoute
              path={"/quote-upload"}
              component={RfqVsQuoteChecker}
            />
            <PrivateRoute
              path={"/invoice-upload"}
              component={InvoiceVsPOChecker}
            />
            <PrivateRoute
              path={"/invoice-uploadd"}
              component={InvoiceVsPOCheckerDemo}
            />
            <PrivateRoute
              path={"/acknowledgement-upload"}
              component={AcknowledgementVsPOChecker}
            />
            <PrivateRoute
              path={"/packingslip-upload"}
              component={PackingSlipVsPOChecker}
            />
            <PrivateRoute
              path={"/packingslip-uploadd"}
              component={PackingSlipVsPOCheckerDemo}
            />
            <PrivateRoute path={"/acknowledgement-uploadd"} component={A} />
            <PrivateRoute path={"/po-upload"} component={POUpload} />

            <PrivateRoute path={"/analytics"} component={AnalyticsPage} />
            <PrivateRoute path={"/contract"} component={ContractPage} />
            <PrivateRoute path="/" component={DashboardPage} exact />
            <Route path="*" render={() => <ExternalRedirect url="/" />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </UserDataProvider>
  </GoogleOAuthProvider>,
);
